<template>
    <div class="main" v-loading="loading">
        <div class="tab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in tabList" :key="index">
                    <div class="listData" v-if="list.list.length > 0">
                        <!-- <div class="listData"> -->
                        <!-- 已发布 -->
                        <div class="every_data" v-for="(item, index) in list.list" :key="index">
                            <!-- <div class="every_data"> -->
                            <div class="data_content flex_center_a">
                                <div class="data_img">
                                    <!-- <image class="allImg" :src="item.thumbnail" mode="aspectFill"></image> -->
                                    <img :src="item.thumbnail" alt="">
                                </div>
                                <div class="content">
                                    <div class="data_title flex_between">
                                        {{ item.warehouseName }}
                                        <div class="data_mark2"
                                            v-if="item.status == 1 && item.auditStatus != 1 && item.auditStatus != 3">
                                            <span>已发布</span>
                                        </div>
                                        <div class="data_mark2"
                                            v-if="item.status == 2 && item.auditStatus != 1 && item.auditStatus != 3">

                                            <span>已下架</span>

                                        </div>
                                        <div class="data_mark2" v-if="item.auditStatus == 1">

                                            <span>审核中</span>
                                        </div>
                                        <div class="data_mark2" v-if="item.auditStatus == 3">
                                            <span>审核失败</span>
                                        </div>
                                    </div>
                                    <div class="data_label flex_center_a_w">
                                        <div class="every_label" v-for="(items, indexs) in item.categoryArr" :key="indexs">
                                            <!-- <div class="every_label"> -->
                                            {{ items.categoryName }}
                                        </div>
                                    </div>

                                    <div class="data_address flex_between">
                                        {{ item.oneName }}-{{ item.twoName }}
                                        <div class="data_btn flex_between"
                                            v-if="item.status == 1 && item.auditStatus != 1 && item.auditStatus != 3">
                                            <!-- <div class="data_btn flex_between"> -->

                                            <div class="btns flex_between">
                                                <div class="btn1 btn" @click="editRent(item.warehouseId)">
                                                    <!-- <div class="btn1 btn" @click="fabuEdit = true"> -->
                                                    编辑
                                                </div>
                                                <div class="btn2 btn" @click="xiajia(item.warehouseId, 2)">
                                                    <!-- <div class="btn2 btn"> -->
                                                    下架
                                                </div>
                                                <div class="btn3 btn" @click="shanchu(item.warehouseId)">
                                                    <!-- <div class="btn3 btn"> -->
                                                    删除
                                                </div>
                                            </div>
                                        </div>
                                        <div class="data_btn flex_between"
                                            v-if="item.status == 2 && item.auditStatus != 1 && item.auditStatus != 3">
                                            <!-- <div class="data_btn flex_between"> -->

                                            <div class="btns flex_between">
                                                <div class="btn1 btn" @click="editRent(item.warehouseId)">
                                                    编辑
                                                </div>
                                                <div class="btn2 btn" @click="xiajia(item.warehouseId, 1)">
                                                    上架
                                                </div>
                                                <div class="btn3 btn" @click="shanchu(item.warehouseId)">
                                                    删除
                                                </div>
                                            </div>
                                        </div>
                                        <div class="data_btn flex_between" v-if="item.auditStatus == 1">
                                            <!-- <div class="data_btn flex_between"> -->

                                            <div class="btns flex_between">

                                                <div class="btn2 btn"
                                                    @click="goAudit(1, item.warehouseId, item.refuseReason)">
                                                    <!-- <div class="btn2 btn"> -->
                                                    审核详情
                                                </div>

                                            </div>

                                        </div>
                                        <div class="data_btn flex_between" v-if="item.auditStatus == 3">
                                            <!-- <div class="data_btn flex_between"> -->

                                            <div class="btns flex_between">
                                                <div class="btn2 btn"
                                                    @click="goAudit(0, item.warehouseId, item.refuseReason)">
                                                    <!-- <div class="btn2 btn"> -->
                                                    审核详情
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>
                    <div v-else>
                        <el-empty :image-size="200"></el-empty>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="flag" style="position: relative;">
            <!-- 分页器 -->
            <div class="items"
                style="padding-bottom: 50px;margin-top: 50px;display: flex;justify-content: center;position: absolute;right: 0;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage4" :page-sizes="[10]" :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper" :total="list.count">
                </el-pagination>
            </div>
        </div>
        <div style="height: 200px;"></div>
        <div class="popup popup1" style="z-index: 0 !important;">
            <el-dialog :close-on-click-modal="false" top="160px" class="my-dialog" style="z-index: 0 !important;"
                title="仓库租赁信息发布" :center="true" :visible.sync="fabuEdit" width="1100px" :before-close="handleClose">
                <div class="form" v-show="step == 1" @click="closeAll">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/fabu/jbxx1.png" alt="">
                            <img src="../../assets/fabu/tpxx.png" alt="">
                            <img src="../../assets/fabu/wzxx.png" alt="">
                        </div>
                    </div>
                    <div class="form_con ">
                        <el-form :model="param" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
                            <div class="form_row">
                                <el-form-item class="every_form" label="仓库名称" prop="warehouseName">
                                    <el-input class="form_inp" placeholder="请输入" v-model="param.warehouseName"></el-input>
                                </el-form-item>
                                <el-form-item class="every_form" label="仓库经纪人" prop="brokerId">
                                    <el-select @change="changeBroker" class="form_inp" v-model="brokerName"
                                        placeholder="请选择">
                                        <el-option v-for="item in selectAll.broker" :key="item.brokerId"
                                            :label="item.brokerName" :value="item.brokerId"></el-option>

                                    </el-select>

                                </el-form-item>
                            </div>
                            <div class="form_row" style="display: flex;">
                                <el-form-item class="every_form" label="区域" prop="province" style="position:relative">


                                    <el-cascader :props="propsRent" @change="changeFnRent">
                                    </el-cascader>
                                    <div v-if="show1" style="position:absolute;color:#606266;font-size:10px;left:10px;top:10px;background-color: #FFFFFF;line-height: 1.3em;font-size: 16px;width: 160px;display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;">
                                        {{ `${addressValue}/${cityValue}/${areaValue}` }}
                                    </div>

                                </el-form-item>

                                <el-form-item class="every_form" label="仓库地址" prop="address">
                                    <el-input class="form_inp" placeholder="请输入" v-model="param.address"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="仓库性质" prop="properties">
                                    <el-select @change="changeNature" class="form_inp" v-model="natureName"
                                        placeholder="请选择">
                                        <el-option v-for="item in natureList" :key="item.value" :label="item.name"
                                            :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- <el-form-item class="every_form" label="存放品类" prop="categoryIds">
                                    <div class="form_inp" style="position: relative;">
                                        <div style="position: absolute;z-index: 9999;width: 180px;">
                                            <el-select :multiple-limit="4" @change="changeCategory"
                                                style="position: absolute;top: 0;z-index: 0;" v-model="typeSelect" multiple
                                                placeholder="请选择(最多四个)">
                                                <el-option v-for="item in selectAll.common_category" :key="item.categoryId"
                                                    :label="item.categoryName" :value="item.categoryId">
                                                </el-option>
                                            </el-select>
                                        </div>

                                    </div>
                                </el-form-item> -->

                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" style="width: 100%;" label="存放品类" prop="categoryIds">
                                    <div class="form_inp" style="position: relative;display: flex;width: 100%; ">
                                        <span @click.stop="cfpl = !cfpl">选择</span>
                                        <div v-if="cfpl"
                                            style="width: 340px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                            <el-select style="width: 214px;" :multiple-limit="4" @change="changeCategory"
                                                v-model="typeSelect" multiple placeholder="请选择(最多四个)">
                                                <el-option v-for="item in selectAll.common_category" :key="item.categoryId"
                                                    :label="item.categoryName" :value="item.categoryId">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div style="width: calc(100% - 50px);">
                                            <div style="display: flex;flex-wrap:wrap;">
                                                <div style="padding: 0px 20px;margin-left: 10px;margin-bottom:10px; border: 1px solid #EEEEEE;color:#333333;border-radius: 4px;"
                                                    v-for="(item, index) in cateSelectList" :key="index">
                                                    {{ item }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </el-form-item>
                            </div>
                            <div class="form_row" style="position: relative;">

                                <el-form-item class="every_form" label="仓库总占地面积" prop="landArea">
                                    <!-- :οninput="param.landArea = param.landArea.match(/\d+(\.\d{0,2})?/) ? param.landArea.match(/\d+(\.\d{0,2})?/)[0] : ''" -->
                                    <el-input @input="landArea" class="form_inp" placeholder="请输入"
                                        v-model="param.landArea"></el-input><span>万平方米</span>
                                </el-form-item>


                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="露天货场面积" prop="outdoorsArea">
                                    <!-- :οninput="param.outdoorsArea = param.outdoorsArea.match(/\d+(\.\d{0,2})?/) ? param.outdoorsArea.match(/\d+(\.\d{0,2})?/)[0] : ''" -->
                                    <el-input @input="outdoorsArea" class="form_inp" placeholder="请输入"
                                        v-model="param.outdoorsArea"></el-input><span>万平方米</span>
                                </el-form-item>
                                <el-form-item class="every_form" label="库房面积" prop="warehouseArea">
                                    <!-- :οninput="param.warehouseArea = param.warehouseArea.match(/\d+(\.\d{0,2})?/) ? param.warehouseArea.match(/\d+(\.\d{0,2})?/)[0] : ''" -->
                                    <el-input @input="warehouseArea" class="form_inp" placeholder="请输入"
                                        v-model="param.warehouseArea"></el-input><span>万平方米</span>
                                </el-form-item>

                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" style="width: 100%;" label="是否有铁路专用线" prop="railwayIsHave">
                                    <el-radio-group @input="changeTielu" class="form_inp" style="width: 30%;"
                                        v-model="param.railwayIsHave">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>

                                    <el-input class="form_inp" :disabled="tieluDis" style="width: 20%;" placeholder="请输入"
                                        v-model="param.railwayCount"></el-input><span style="margin-right: 10px;">条</span>
                                    <el-input :disabled="tieluDis" @input="railwayLength" class="form_inp"
                                        style="width: 20%;" placeholder="请输入"
                                        v-model="param.railwayLength"></el-input><span>公里</span>
                                    <!-- :οninput="param.railwayLength = param.railwayLength.match(/\d+(\.\d{0,2})?/) ? param.railwayLength.match(/\d+(\.\d{0,2})?/)[0] : ''" -->

                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" style="width: 100%;" label="是否上线货兑宝">
                                    <el-radio-group class="form_inp" style="width: 30%;" v-model="param.isCustomer">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>


                            <div class="form_row">

                                <el-form-item class="every_form" style="width: 100%;" label="仓库类型">
                                    <div class="form_inp" style="position: relative;display: flex;width: 100%; ">
                                        <span @click.stop="cklx = !cklx" style="cursor: pointer;
                            font-size: 16px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #00B079;">选择</span>
                                        <div v-if="cklx"
                                            style="width: 340px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                            <el-select :multiple-limit="4" class="form_inp" @change="changeType"
                                                style="position: absolute;top: 0;z-index: 0;" v-model="wareSelect" multiple
                                                placeholder="请选择(最多四个)">
                                                <el-option v-for="item in selectAll.warehouse_extra" :key="item.tagId"
                                                    :label="item.itemName" :value="item.tagId">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div style="width: calc(100% - 50px);">
                                            <div style="display: flex;flex-wrap:wrap;">
                                                <div style="padding: 0px 20px;margin-left: 10px;border: 1px solid #EEEEEE;color:#333333;border-radius: 4px;"
                                                    v-for="(item, index) in wareSelectList" :key="index">
                                                    {{ item }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </el-form-item>

                            </div>

                            <!-- <div class="form_row">
                                
                            </div> -->

                            <div class="form_row" style="position: relative;">
                                <!-- <span style="position: absolute;color: #F56C6C;top: 7px;left: 44px;font-size: 20px;">*</span> -->
                                    <el-form-item
                                    label="物联网功能" prop="">
                                    <!-- <div class="form_inp" style="position: relative;">

                                        <span @click="wlw = !wlw">选择</span>
                                        <div v-if="wlw"
                                            style="width: 200px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                            <el-select @change="changeEffect" style="position: absolute;top: 0;z-index: 0;"
                                                v-model="effectNameSelect" multiple placeholder="请选择">
                                                <el-option v-for="item in selectAll.warehouse_iot" :key="item.tagId"
                                                    :label="item.itemName" :value="item.tagId">
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </div> -->
                                    <el-checkbox-group @change="changeEffect" v-model="effectNameSelect">
                                        <el-checkbox v-for="item in selectAll.warehouse_iot" :key="item.tagId"
                                            :label="item.tagId" name="type">{{ item.itemName }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>

                            </div>
                            <div class="form_row" style="width: 100%;">

                                <el-form-item style="width: 100%;" label="特色标签">
                                    <!-- <el-radio-group @change="changeLabel" class="form_inp" v-model="param.featureStr">
                                        <el-radio v-for="item in selectAll.warehouse_feature" :key="item.tagId"
                                            :label="item.tagId">{{
                                                item.itemName }}</el-radio>
                                    </el-radio-group> -->
                                    <el-checkbox-group @change="changeBQ" v-model="biaoqian">
                                        <el-checkbox v-for="item in selectAll.warehouse_feature" :key="item.tagId"
                                            :label="item.tagId" name="type">{{ item.itemName }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="防火等级" prop="fireRating">
                                    <el-select @change="changeLevel" class="form_inp" v-model="levelName" placeholder="请选择">
                                        <el-option v-for="item in levelList" :key="item.type" :label="item.name"
                                            :value="item.type"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="every_form" label="建筑结构" prop="propertyStructure">
                                    <el-select @change="changeJiegou" class="form_inp" v-model="jiegouName"
                                        placeholder="请选择">
                                        <el-option v-for="item in jiegouList" :key="item.type" :label="item.name"
                                            :value="item.type"></el-option>
                                    </el-select>
                                </el-form-item>


                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="地面承重" prop="bearing">
                                    <!-- :οninput="param.bearing = param.bearing.match(/\d+(\.\d{0,2})?/) ? param.bearing.match(/\d+(\.\d{0,2})?/)[0] : ''" -->
                                    <el-input @input="bearing" class="form_inp" placeholder="请输入"
                                        v-model="param.bearing"></el-input><span>t/㎡</span>
                                </el-form-item>
                                <el-form-item class="every_form" label="库房顶高" prop="high">
                                    <!-- :οninput="param.high = param.high.match(/\d+(\.\d{0,2})?/) ? param.high.match(/\d+(\.\d{0,2})?/)[0] : ''" -->
                                    <el-input @input="high" class="form_inp" placeholder="请输入"
                                        v-model="param.high"></el-input><span>m</span>
                                </el-form-item>

                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="月台宽度" prop="railwayPlatformWidth">
                                    <!-- :οninput="param.railwayPlatformWidth = param.railwayPlatformWidth.match(/\d+(\.\d{0,2})?/) ? param.railwayPlatformWidth.match(/\d+(\.\d{0,2})?/)[0] : ''" -->
                                    <el-input @input="railwayPlatformWidth" class="form_inp" placeholder="请输入(若无数据填写0)"
                                        v-model="param.railwayPlatformWidth"></el-input><span>m</span>
                                </el-form-item>
                                <el-form-item class="every_form" label="月台高度" prop="platformHigh">
                                    <!-- :οninput="param.platformHigh = param.platformHigh.match(/\d+(\.\d{0,2})?/) ? param.platformHigh.match(/\d+(\.\d{0,2})?/)[0] : ''" -->
                                    <el-input class="form_inp" @input="platformHigh" placeholder="请输入(若无数据填写0)"
                                        v-model="param.platformHigh"></el-input><span>m</span>
                                </el-form-item>

                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="雨棚宽度" prop="deep">
                                    <!-- :οninput="param.deep = param.deep.match(/\d+(\.\d{0,2})?/) ? param.deep.match(/\d+(\.\d{0,2})?/)[0] : ''" -->
                                    <el-input class="form_inp" @input="deep" placeholder="请输入(若无数据填写0)"
                                        v-model="param.deep"></el-input><span>m</span>
                                </el-form-item>

                                <el-form-item class="every_form" label="升降平台" prop="columnSpacing">
                                    <el-select class="form_inp" v-model="param.columnSpacing" placeholder="请选择">
                                        <el-option label="有" :value="1"></el-option>
                                        <el-option label="无" :value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="服务功能" prop="service">
                                    <el-input type="textarea" placeholder="每项功能中间用/隔开，例如功能1/功能2/功能3"
                                        v-model="param.service"></el-input>
                                </el-form-item>
                            </div>
                            <div style="padding-bottom: 50px;" class="fuwenben">
                                <el-form-item label="仓库简介" prop="description">
                                    <quill-editor v-model="param.description" ref="myQuillEditor" :options="editorOption"
                                        @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
                                        @change="onEditorChange($event)">
                                    </quill-editor>
                                </el-form-item>

                            </div>
                            <div class="form_row" style="width: 100%;">

                                <el-form-item style="width: 80%;text-align: center;">
                                    <el-button style="width: 60%;" type="primary"
                                        @click.stop="submitForm('ruleForm')">下一步</el-button>
                                </el-form-item>
                            </div>





                        </el-form>

                    </div>
                </div>
                <div class="form" v-show="step == 2">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/fabu/jbxx1.png" alt="">
                            <img src="../../assets/fabu/tpxx1.png" alt="">
                            <img src="../../assets/fabu/wzxx.png" alt="">
                        </div>

                    </div>
                    <div class="form_con1" style="width: 64%;padding-top:50px;margin:0 auto;">
                        <el-form :model="param" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                            <div class="form_row">
                                <span style="color:red;position: relative;top: 32px;left: 12px;">*</span><el-form-item
                                    class="every_form" style="padding-left:8px;position: relative;" label="仓库封面图">
                                    <el-upload :on-exceed="handleChange1" :accept="'.jpg,.png'" :limit="1"
                                        class="upload-demo" :action="uploadPath + '/system/front/upload_file'"
                                        :on-preview="handlePreview" :on-success="uploadFengmian"
                                        :before-upload="handleBefore" :on-remove="handleRemove" :file-list="fileList1"
                                        list-type="picture">
                                        <div style="display: flex;position: relative;">
                                            <el-button style="width: 100px;height: 40px;" size="small"
                                                type="primary">点击上传</el-button>
                                            <div style="padding:8px 0;margin-left:10px;">
                                                <img @mouseenter="showWarn1 = !showWarn1"
                                                    @mouseleave="showWarn1 = !showWarn1" style="width: 24px;height: 24px;"
                                                    src="@/assets/person/warn.png" alt="" slot="reference">
                                            </div>
                                            <div class="title_other" v-if="showWarn1">
                                                1.图片上传格式为jpg、png<br>
                                                2.建议图片单张大小在5M以内<br>
                                                3.图片宽高比建议采用3:2<br>
                                                4.图片名称不宜过长<br>
                                            </div>
                                        </div>
                                    </el-upload>
                                    <div style="position: absolute;top: 2px;left: 150px;">

                                        <a href="https://www.secaibi.com/tools/%e5%9c%a8%e7%ba%bf%e5%9b%be%e7%89%87%e5%8e%8b%e7%bc%a9/"
                                            target="_blank">前往在线压缩地址</a>

                                    </div>
                                </el-form-item>
                            </div>
                            <div class="form_row" style="display: flex;align-items:flex-start">
                                <span style="color:red;position: relative;top: 12px;left: 30px;">*</span><el-form-item
                                    class="every_form" style="position: relative;width: 100%;" label="图片预览" prop="name">
                                    <el-upload :multiple="true" :on-exceed="handleChange" :limit="5" :accept="'.jpg,.png'"
                                        class="upload-demo" :action=" uploadPath + '/system/front/upload_file'"
                                        :on-preview="handlePreview" :on-success="uploadYulan" :before-upload="handleBefore"
                                        :on-remove="handleRemove1" :file-list="fileList2" list-type="picture">
                                        <div style="display: flex;position: relative;">
                                            <el-button style="width: 100px;height: 40px;" size="small"
                                                type="primary">点击上传</el-button>
                                            <div style="padding:8px 0;margin-left:10px;">
                                                <img @mouseenter="showWarn2 = !showWarn2"
                                                    @mouseleave="showWarn2 = !showWarn2" style="width: 24px;height: 24px;"
                                                    src="@/assets/person/warn.png" alt="" slot="reference">
                                            </div>
                                            <div class="title_other" v-if="showWarn2">
                                                1.图片上传格式为jpg、png<br>
                                                2.建议图片单张大小在5M以内<br>
                                                3.图片宽高比建议采用3:2<br>
                                                4.图片名称不宜过长<br>
                                                5.图片上传张数最多5张
                                            </div>
                                        </div>

                                    </el-upload>
                                    <div style="position: absolute;top: 3px;left: 150px;width: 200rpx;">

                                        <a href="https://www.secaibi.com/tools/%e5%9c%a8%e7%ba%bf%e5%9b%be%e7%89%87%e5%8e%8b%e7%bc%a9/"
                                            target="_blank">前往在线压缩地址</a>

                                    </div>
                                </el-form-item>

                            </div>

                            <div class="form_row">
                                <el-form-item class="every_form" label="VR预览" prop="name">
                                    <div style="font-size: 16px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #CCCCCC;">VR实景预览能更好的展示仓库各方位信息，以便于出租仓库，如需此功能请联系客服</div>
                                    <div class="btn" style="display: flex;justify-content:space-between;padding:50px;">
                                        <div style="width: 35%; line-height: 48px;text-align:center;cursor: pointer;
height: 48px;
background: #00B079;
border-radius: 10px 10px 10px 10px;font-size: 20px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;border:none;" @click="step = 1">上一步</div>
                                        <div style="width: 35%;
height: 48px;
background: #00B079;
border-radius: 10px 10px 10px 10px;font-size: 20px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;border:none;text-align:center;line-height: 48px;cursor: pointer;" @click="tupianNext">下一步</div>
                                    </div>
                                </el-form-item>


                            </div>
                        </el-form>


                    </div>
                </div>
                <div class="form" v-show="step == 3">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/fabu/jbxx1.png" alt="">
                            <img src="../../assets/fabu/tpxx1.png" alt="">
                            <img src="../../assets/fabu/wzxx1.png" alt="">
                        </div>
                    </div>
                    <div class="form_con">
                        <el-form :model="param" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
                            <div class="form_row">

                                <el-form-item class="every_form" label="经纬度拾取地址">
                                    <div class="form_inp">
                                        <span @click="selectLat">选择</span>
                                    </div>
                                </el-form-item>
                            </div>

                            <div class="form_row">
                                <span style="color:red;position: relative;top: -8px;left: 68px;">*</span><el-form-item
                                    class="every_form" label="仓库经度" prop="name">
                                    <el-input disabled style="width: 240px;" placeholder="请输入"
                                        v-model="param.latitude"></el-input>
                                </el-form-item>

                            </div>
                            <div class="form_row">

                                <span style="color:red;position: relative;top: -8px;left: 68px;">*</span><el-form-item
                                    class="every_form" label="仓库纬度" prop="region">
                                    <el-input disabled style="width: 240px;" placeholder="请输入"
                                        v-model="param.longitude"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item label="关联地点:">
                                    <div style="display: flex;">
                                        <div>
                                            例如仓库附近的港口，车站等
                                        </div>
                                        <div style="width: 92px;margin-left:50px;text-align:center;
height: 42px;
background: #00B079;
border-radius: 10px 10px 10px 10px;color:#FFFFFF;border:none;cursor: pointer;" @click="addAddress">添加</div>
                                    </div>

                                </el-form-item>

                            </div>
                            <div style="padding:0 0px" v-if="addFlag">
                                <el-table ref="singleTable" :data="addList" highlight-current-row style="width: 100%">
                                    <el-table-column label="序号" type="index" width="60">
                                    </el-table-column>


                                    <el-table-column @change="changeAddressTypes" label="类别" width="160" prop="typeName">
                                        <template slot-scope="scope">
                                            <el-select style="z-index: 9999999999999999 !important;"
                                                @change="handleEdit(scope.$index, scope.row)" v-model="scope.row.type"
                                                placeholder="请选择">
                                                <el-option v-for="item in selectAll.map" :key="item.tagId"
                                                    :label="item.itemName" :value="item.tagId"></el-option>
                                            </el-select>
                                        </template>

                                    </el-table-column>
                                    <el-table-column prop="name" label="名称">
                                    </el-table-column>
                                    <el-table-column prop="address" label="地址">
                                    </el-table-column>
                                    <el-table-column prop="latitude" label="经度" width="110">
                                    </el-table-column>
                                    <el-table-column prop="longitude" label="纬度" width="110">
                                    </el-table-column>
                                    <el-table-column prop="distance" label="距离(km)" width="80">
                                    </el-table-column>
                                    <el-table-column label="操作">

                                        <template slot-scope="scope">

                                            <el-button size="mini" type="danger"
                                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </div>

                            <div class="form_row" style="margin-top:50px;">
                                <div class="btn"
                                    style="display: flex;justify-content:space-between;padding:0px 100px;width: calc(100% - 200px);">
                                    <div style="width: 35%;line-height: 48px;text-align:center;cursor: pointer;
height: 48px;
background: #00B079;
border-radius: 10px 10px 10px 10px;font-size: 20px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;border:none;" @click="step = 2">上一步</div>
                                    <div style="width: 35%;
height: 48px;
text-align:center;
line-height: 48px;
background: #00B079;
border-radius: 10px 10px 10px 10px;font-size: 20px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;cursor: pointer;
color: #FFFFFF;border:none;" @click="nextStep3">确认发布</div>
                                </div>


                            </div>
                        </el-form>
                    </div>
                </div>
            </el-dialog>
        </div>

        <!-- <div class="map">
            <el-dialog top="160px" title="" :center="true" :visible.sync="mapShow" width="60%" :before-close="handleCloses">

                <div id="container" style="width: 100%;height: 500px;"></div>

            </el-dialog>
        </div> -->
        <div v-show="mapShow" class="modal">
            <div class="modal-mask" @click="mapShow = false"></div>
            <div class="modal-wrapper">
                <div class="modal-container">
                    <span class="close" @click="mapShow = false">&times;</span>
                    <h2>地图选点</h2>
                    <div style="width: 800px;height: 600px;margin-top:30px;">
                        <Map @location="getMap"></Map>
                    </div>
                </div>
            </div>
        </div>
        <div class="xiajia">
            <el-dialog top="160px" title="下架提醒" :visible.sync="showXiajia" width="700px" center>

                <div style="text-align:center;font-size: 18px;
font-family: PingFang SC-Medium, PingFang SC;
font-weight: 500;
color: #999999;">确定要下架该条出租信息吗，下架后系统会为您保存三个月</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="showXiajia = false">取消</el-button>
                    <el-button type="primary" @click="xiajiaYes">确定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="xiajia">
            <el-dialog top="160px" title="删除提醒" :visible.sync="showDelete" width="700px" center>
                <div style="text-align:center;font-size: 18px;
font-family: PingFang SC-Medium, PingFang SC;
font-weight: 500;
color: #999999;">确定要删除该条出租信息吗</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="showDelete = false">取消</el-button>
                    <el-button type="primary" @click="deleteYes">确定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="shenhe">
            <el-dialog top="160px" title="仓库租赁信息发布" :visible.sync="shenhe" width="700px" center>
                <div class="shenheimg">
                    <img v-if="type == 1" src="@/assets/person/shenhe.png" alt="">
                    <img v-if="type == 0" src="@/assets/person/shenheno.png" alt="">
                </div>
                <div class="tishi">
                    <div v-if="type == 1"> 后台审核中，请耐心等待</div>
                    <div v-if="type == 1">我们会在24小时内给您反馈</div>
                    <div v-if="type == 0">审核失败，请更正后重新提交</div>
                </div>
                <div class="reason" v-if="type == 0">
                    {{ reason }}
                </div>

                <div class="buttn" @click="shenhe = false" v-if="type == 1">
                    我知道了
                </div>
                <div class="buttn" v-if="type == 0" @click="xiugaiShenhe">
                    去修改
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import myEditor from "../../components/myEditor/index.vue"
import Map from "../../components/myMap/index.vue";
import axios from "axios";
export default {
    components: {
        myEditor,
        Map
    },
    name: "TestIndex",
    data() {
        let that = this;
        return {
            uploadPath: process.env.VUE_APP_BASE_API,
            show1: false,
            wareSelectList: [],
            loading: false,
            propsRent: {
                lazy: true,
                label: 'name', value: 'id',
                lazyLoad: async (node, resolve) => {
                    console.log(node, 9999999999)
                    const { level } = node;
                    if (level >= 1) {
                        if (level == 3) {
                            return
                        }
                        let data = await this.getArea(node.value) //[{ id: 1, name: 222 }]//
                        let res = data.map(i => {
                            i.leaf = level >= 2
                            return i
                        })
                        resolve(res);
                    } else {

                        resolve(this.addressList);
                    }
                }
            },
            error: false,
            biaoqian: [],
            shenheId: '',
            type: 1,
            reason: '',
            shenhe: false,
            cangku: '', map: null,
            showXiajia: false,
            showDelete: false,
            mapShow: false,
            mapOne: 1,
            addressValue: '',
            cityValue: '',
            areaValue: '',
            valueAddressType: "",
            cateSelectList: [],
            fileList: [],
            cfpl: false,
            cklx: false,
            wlw: false,
            cklx: false,
            checked1: true,
            checked2: false,
            checked3: false,
            checked4: true,
            checkboxGroup1: [],
            checkboxGroup2: [],
            content: '',
            editorOption: {
                placeholder: "请编辑内容",
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{ size: ["small", false, "large", "huge"] }],
                        [{ align: [] }]
                    ]
                }
            },
            tieluDis: true,
            contentDefault: "",
            rules: {

                brokerId: [
                    { required: true, message: '请选择仓库经纪人', trigger: 'change' }
                ],
                province: [
                    { required: true, message: '请选择区域', trigger: 'change' }
                ],
                properties: [
                    { required: true, message: '请选择仓库性质', trigger: 'change' }
                ],
                columnSpacing: [
                    { required: true, message: '是否有升降平台', trigger: 'change' }
                ],
                warehouseType: [
                    { required: true, message: '请选择仓库类型', trigger: 'change' }
                ],
                // iotAbility: [
                //     { required: true, message: '请选择物联网功能', trigger: 'change' }
                // ],
                fireRating: [
                    { required: true, message: '请选择防火等级', trigger: 'change' }
                ],
                propertyStructure: [
                    { required: true, message: '请选择建筑结构', trigger: 'change' }
                ],
                categoryIds: [
                    { required: true, message: '请选择仓库品类', trigger: 'change' }
                ],
                warehouseName: [
                    { required: true, message: '请输入仓库名称，应为2到20个字符', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请输入仓库地址', trigger: 'blur' },
                ],
                landArea: [
                    { required: true, message: '请输入仓库总占地面积', trigger: 'blur' },
                ],
                outdoorsArea: [
                    { required: true, message: '请输入露天货场面积', trigger: 'blur' },
                ],
                warehouseArea: [
                    { required: true, message: '请输入库房面积', trigger: 'blur' },
                ],
                bearing: [
                    { required: true, message: '请输入地面承重', trigger: 'blur' },
                ],
                high: [
                    { required: true, message: '请输入库房高度', trigger: 'blur' },
                ],
                railwayPlatformWidth: [
                    { required: true, message: '请输入月台宽度', trigger: 'blur' },
                ],
                platformHigh: [
                    { required: true, message: '请输入月台高度', trigger: 'blur' },
                ],
                deep: [
                    { required: true, message: '请输入雨棚深度', trigger: 'blur' },
                ],
                service: [
                    { required: true, message: '请输入服务功能', trigger: 'blur' },
                ],



            },
            fabuEdit: false,
            activeName: 'first',
            tabList: [
                {
                    label: "全部",
                    name: "first",
                },
                {
                    label: "已发布",
                    name: "second",
                },
                {
                    label: "已下架",
                    name: "third",
                },
                {
                    label: "审核中",
                    name: "fourth",
                },
                {
                    label: "审核失败",
                    name: "fifth",
                }
            ],
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: '',
            params: {
                type: 1,
                status: 0,
                upDown: 0,
                // uid: '',
                page: 1,
                viewType: 1
            },
            paramXj: {
                warehouseId: '',
                opType: 3,
                status: ''
            },
            list: {
                list: []
            },
            currentPage4: 1,
            showReturn: false,
            showDelete: false,
            tielu: false, //是否有铁路专用线
            shangxian: false, //是否上线兑货宝
            labelIndex: -1,
            dateStart: '',
            datePlan: '',
            brokerIndex: 0, //仓库经纪人
            brokerList: [],
            brokerName: "",
            addressIndex: 0, //仓库地址
            addressList: [],
            cityList: [],
            areaList: [],
            oldpProvinceDataList: [

            ],
            newProvinceDataList: [
                [],
                [],
                []
            ],
            arr1: [],
            arr2: [],
            multiIndex: [0, 0, 0],
            natureIndex: 0, //仓库性质
            natureList: [{
                name: "私有",
                value: 0
            },
            {
                name: "国有",
                value: 1
            }
            ],
            natureName: "",
            categoryIndex: 0, //存放品类
            categoryList: [],
            typeSelect: [],
            typeFlag: true,
            typeIndex: 0, //仓库类型
            typeList: [],
            warehouse_extra: '',
            effectIndex: 0, //物联网功能
            effectList: [],
            effectNameSelect: [],
            wareSelect: [],
            labelList: [{
                name: '选项一'
            },
            {
                name: '选项二'
            },
            {
                name: '选项三'
            }
            ],

            jiegouIndex: 0, //建筑结构
            jiegouName: '',
            jiegouList: [{
                name: '砖混',
                type: 1
            },
            {
                name: '钢混',
                type: 2
            },
            {
                name: '轻钢结构',
                type: 3
            },
            {
                name: '简易库',
                type: 4
            }
            ],
            levelIndex: 0, //防火等级
            levelName: '',
            levelList: [{
                name: '甲类',
                type: 1
            },
            {
                name: '乙类',
                type: 2
            },
            {
                name: '乙二类',
                type: 3
            },
            {
                name: '丙一类',
                type: 4
            },
            {
                name: '丙二类',
                type: 5
            },
            {
                name: '丁类',
                type: 6
            },
            {
                name: '戊类',
                type: 7
            }
            ],
            pingtaiIndex: 0, //升降平台
            pingtaiName: '',
            pingtaiList: [{
                name: '无',
                type: 0
            },
            {
                name: '有',
                type: 1
            }
            ],
            step: 1,//当前步骤
            stepListTab: [{
                name: '基本信息',
                id: 1,
                checkedImg: '/static/rentout/step1.png',
                unCheckedImg: '/static/rentout/step1.png',

            }, {
                name: '图片信息',
                id: 2,
                checkedImg: '/static/rentout/step2.png',
                unCheckedImg: '/static/rentout/step2n.png',
            }, {
                name: '位置信息',
                id: 3,
                checkedImg: '/static/rentout/step3.png',
                unCheckedImg: '/static/rentout/step3n.png',
            }], //支持字体颜色，线条等变量, //步骤列表
            fileList1: [], //封面图
            fileList2: [], //图片预览
            showWarn1: false,
            showWarn2: false,
            param: {
                opType: 2, //操作类型 1：添加 2：修改，此时ALIVE_FLAG修改为0就是删除。3：上架下架 4：删除
                warehouseName: '', //仓库名称
                brokerId: '', //仓库经纪人id
                address: '', //仓库地址
                province: '',
                city: '',
                county: '',
                properties: '', //仓库性质
                categoryIds: '', //仓库品类
                landArea: '', //占地面积
                outdoorsArea: '', //露天货场面积
                warehouseArea: '', //仓库面积
                warehouseType: '', //仓库类型
                iotAbility: '', //物联网功能
                railwayIsHave: 0, //是否铁路专线
                railwayCount: '', //铁路条数
                railwayLength: '', //铁路长度
                isCustomer: 0, //是否上线货兑宝
                featureStr: '', //特色标签
                fireRating: '', //防火等级
                propertyStructure: '', //建筑结构
                bearing: '', //地面承重
                high: '', //库房顶高
                railwayPlatformWidth: '', //月台宽度
                platformHigh: '', //月台高度
                deep: '', //雨棚深度
                columnSpacing: '', //升降平台（0无1有）
                service: '', //服务功能
                description: '', //仓库简介
                banners: '', //banner图
                longitude: '', //经度
                latitude: '', //纬度
                cover: '', //封面图
                relationAddress: "", //关联地点

            },

            fengmian: '',
            banner: [],
            selectAll: {}, //初始化选项列表
            addList: [], //关联地点暂存
            deleteIndex: -1,
            everyAdd: {
                name: '', //名称
                address: '', //地点
                longitude: '', //经纬度
                latitude: '',
                distance: '', //距离
                type: '' //类型
            }, //每个关联地点的信息
            addFlag: true,
            cangku: '',
            lxIndex: 0,
            addIndex: 0,
            detailParam: {
                type: 1,
                viewType: 1,
                id: ''
            },
            options: {},
            deleteParam: {
                warehouseId: '',
                opType: 4,
            },
            ruleRent: [{
                name: "warehouseName",
                checkType: "isNotNull2_20",
                errorMsg: "请输入仓库名称"
            },
            {
                name: "brokerId",
                checkType: "isNotNull",
                errorMsg: "请选择仓库经纪人"
            },
            {
                name: "county",
                checkType: "isNotNull",
                errorMsg: "请选择区域"
            },
            {
                name: "address",
                checkType: "isNotNull",
                errorMsg: "请选择仓库地址"
            },
            {
                name: "properties",
                checkType: "isNotNull",
                errorMsg: "请选择仓库性质"
            },
            {
                name: "categoryIds",
                checkType: "isNotNull",
                errorMsg: "请选择仓库品类"
            },
            {
                name: "landArea",
                checkType: "isNotNull",
                errorMsg: "请输入占地面积"
            },
            {
                name: "outdoorsArea",
                checkType: "isNotNull",
                errorMsg: "请输入露天货场面积"
            },
            {
                name: "warehouseArea",
                checkType: "isNotNull",
                errorMsg: "请输入仓库面积"
            },
            {
                name: "warehouseType",
                checkType: "isNotNull",
                errorMsg: "请选择仓库类型"
            },
            // {
                // name: "iotAbility",
                // checkType: "isNotNull",
                // errorMsg: "请选择物联网功能111111111"
            // },
            {
                name: "fireRating",
                checkType: "isNotNull",
                errorMsg: "请选择防火等级"
            },
            {
                name: "propertyStructure",
                checkType: "isNotNull",
                errorMsg: "请选择建筑结构"
            },
            {
                name: "bearing",
                checkType: "isNotNull",
                errorMsg: "请输入地面承重"
            },
            {
                name: "high",
                checkType: "isNotNull",
                errorMsg: "请输入库房高度"
            },
            {
                name: "railwayPlatformWidth",
                checkType: "isNotNull",
                errorMsg: "请输入月台宽度"
            },
            {
                name: "platformHigh",
                checkType: "isNotNull",
                errorMsg: "请输入月台高度"
            },
            {
                name: "deep",
                checkType: "isNotNull",
                errorMsg: "请输入雨棚深度"
            },
            {
                name: "columnSpacing",
                checkType: "isNotNull",
                errorMsg: "是否有升降平台"
            },
            {
                name: "service",
                checkType: "isNotNull",
                errorMsg: "请输入服务功能"
            }
            ],
            flag: true,
        };
    },
    mounted() {

    },
    created() {

        this.rentList()
        this.getSelect()
        this.getAreaSheng()
    },
    methods: {
        landArea(e) {
            let value = e.replace(/[^-\d.]/g, '') // 只能输入.和-和数字

            value = value.replace(/^[.]/g, '') //第一个字符不能是.

            value = value.replace(/(\d+|.)-/g, '$1') // 数字和.后面不能接-,不能出现类似 12.-

            value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似

            value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')// 最多保留2位小数
            console.log();
            this.param.landArea = value
        },
        outdoorsArea(e) {
            let value = e.replace(/[^-\d.]/g, '') // 只能输入.和-和数字
            value = value.replace(/^[.]/g, '') //第一个字符不能是.
            value = value.replace(/(\d+|.)-/g, '$1') // 数字和.后面不能接-,不能出现类似 12.-
            value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
            value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')// 最多保留2位小数
            this.param.outdoorsArea = value
        },
        warehouseArea(e) {
            let value = e.replace(/[^-\d.]/g, '') // 只能输入.和-和数字
            value = value.replace(/^[.]/g, '') //第一个字符不能是.
            value = value.replace(/(\d+|.)-/g, '$1') // 数字和.后面不能接-,不能出现类似 12.-
            value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
            value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')// 最多保留2位小数
            this.param.warehouseArea = value
        },

        railwayLength(e) {
            let value = e.replace(/[^-\d.]/g, '') // 只能输入.和-和数字
            value = value.replace(/^[.]/g, '') //第一个字符不能是.
            value = value.replace(/(\d+|.)-/g, '$1') // 数字和.后面不能接-,不能出现类似 12.-
            value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
            value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')// 最多保留2位小数
            this.param.railwayLength = value
        },

        bearing(e) {
            let value = e.replace(/[^-\d.]/g, '') // 只能输入.和-和数字
            value = value.replace(/^[.]/g, '') //第一个字符不能是.
            value = value.replace(/(\d+|.)-/g, '$1') // 数字和.后面不能接-,不能出现类似 12.-
            value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
            value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')// 最多保留2位小数
            this.param.bearing = value
        },

        high(e) {
            let value = e.replace(/[^-\d.]/g, '') // 只能输入.和-和数字
            value = value.replace(/^[.]/g, '') //第一个字符不能是.
            value = value.replace(/(\d+|.)-/g, '$1') // 数字和.后面不能接-,不能出现类似 12.-
            value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
            value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')// 最多保留2位小数
            this.param.high = value
        },
        railwayPlatformWidth(e) {
            let value = e.replace(/[^-\d.]/g, '') // 只能输入.和-和数字
            value = value.replace(/^[.]/g, '') //第一个字符不能是.
            value = value.replace(/(\d+|.)-/g, '$1') // 数字和.后面不能接-,不能出现类似 12.-
            value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
            value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')// 最多保留2位小数
            this.param.railwayPlatformWidth = value
        },
        platformHigh(e) {
            let value = e.replace(/[^-\d.]/g, '') // 只能输入.和-和数字
            value = value.replace(/^[.]/g, '') //第一个字符不能是.
            value = value.replace(/(\d+|.)-/g, '$1') // 数字和.后面不能接-,不能出现类似 12.-
            value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
            value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')// 最多保留2位小数
            this.param.platformHigh = value
        },
        deep(e) {
            let value = e.replace(/[^-\d.]/g, '') // 只能输入.和-和数字
            value = value.replace(/^[.]/g, '') //第一个字符不能是.
            value = value.replace(/(\d+|.)-/g, '$1') // 数字和.后面不能接-,不能出现类似 12.-
            value = value.replace(/(-)0+(\d+)/g, '$1$2') // 不能出现-01,-02类似
            value = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')// 最多保留2位小数
            this.param.deep = value
        },


        closeAll() {
            this.cklx = false
            this.cfpl = false
        },
        handleChange(file, fileList) {
            console.log(fileList);
            this.$message({
                message: "最多只能上传5张图片",
                type: 'warning',
                offset: 100
            });

        },
        handleChange1(file, fileList) {
            console.log(file, fileList);
            this.$message({
                message: "只能上传1张图片",
                type: 'warning',
                offset: 100
            });
        },
        changeBQ(e) {
            console.log(e);
            this.param.featureStr = e.join(",")
        },
        changeFnRent(e) {
            console.log(e);
            this.param.province = e[0]
            this.param.city = e[1]
            this.param.county = e[2]
            this.show1 = false
        },
        getMap(e) {
            console.log(e);
            if (this.mapOne == 1) {
                this.param.latitude = e.lat * 1
                this.param.longitude = e.lng * 1
                this.mapShow = false
            } else if (this.mapOne == 2) {
                this.addGuanLian(e.address, e.lat, e.lng, e.name)
            }
        },

        tupianNext() {
            if (this.param.cover == '') {
                this.$message({
                    message: "请上传仓库封面图",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.banners == "") {
                this.$message({
                    message: "请上传预览图片",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            this.step = 3
        },
        goAudit(type, id, reason) {
            this.type = type
            this.reason = reason
            this.shenheId = id
            this.shenhe = true

        },
        xiugaiShenhe() {
            this.editRent(this.shenheId)
        },
        // 发布
        async nextStep3() {

            if (this.param.latitude == '' || this.param.longitude == '') {
                // uni.$u.toast("请选取仓库经纬度")
                this.$message({
                    message: "请选取仓库经纬度",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            let flag = false
            for (var i = 0; i < this.addList.length; i++) {
                if (this.addList[i].type == '') {
                    flag = true
                    break
                }
            }
            if (flag) {
                this.$message({
                    message: "请选择关联地址类别",
                    type: 'warning',
                    offset: 100
                });

                return
            }

            console.log(this.param);
            let data = await this.$api.base.rentOut(this.param)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: "提交成功",
                    type: 'success',
                    offset: 100
                });
                this.fabuEdit = false
                this.type = 1
                this.shenhe = true
                this.rentList()
                nav.classList.remove("fixedNav");
            }
            if (data.code == -9999) {
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });
            }
            // 
        },
        clickHandler: async function (e) {
            let lng = e.lnglat.getLng()
            let lat = e.lnglat.getLat()

            console.log(lng, lat);
            // 69d7b3d45c25c1dd6fd1b7072b98fb50
            let url = 'https://restapi.amap.com/v3/geocode/regeo?output=json&location=' + lng + ',' + lat + '&key=69d7b3d45c25c1dd6fd1b7072b98fb50&radius=1000&extensions=all'
            // 在 node.js 用GET请求获取远程图片
            let data = await axios({
                method: 'get',
                url: url,
            })
            console.log(data.data.regeocode);
            let name = data.data.regeocode.addressComponent.streetNumber.street
            let address = data.data.regeocode.formatted_address
            console.log(address);
            if (this.mapOne == 1) {
                this.param.latitude = lat * 1
                this.param.longitude = lng * 1
                this.mapShow = false
            } else if (this.mapOne == 2) {
                this.addGuanLian(address, lat, lng, name)
            }
            this.cangku = address
            //     .then(function (response) {
            //     console.log(response.data.regeocode.formatted_address);
            // });

        },
        addAddress() {
            this.mapOne = 2
            this.mapShow = true
        },
        // 关联地点
        async addGuanLian(address, latitude, longitude, name) {
            if (this.param.latitude == '') {
                uni.$u.toast("请选取仓库经纬度")
                return
            }
            console.log(address, latitude, longitude);
            let obj = {
                name: '', //名称
                address: '', //地点
                longitude: '', //经纬度
                latitude: '',
                distance: '', //距离
                type: "" //类型
            }
            obj.name = name
            obj.address = address
            obj.latitude = latitude * 1
            obj.longitude = longitude * 1
            obj.distance = this.getDistance(this.param.latitude * 1, this.param.longitude * 1, latitude * 1, longitude * 1)
            // addList
            // console.log(this.everyAdd);

            this.addList[this.addList.length] = obj


            this.addFlag = false

            console.log(this.addList);
            setTimeout(() => {
                this.addFlag = true
            }, 10)
            this.param.relationAddress = JSON.stringify(this.addList)
            this.mapShow = false

        },
        // 计算经纬度
        getDistance(lat1, lng1, lat2, lng2) {
            // console.log(lat1, lng1, lat2, lng2)
            var radLat1 = lat1 * Math.PI / 180.0;
            var radLat2 = lat2 * Math.PI / 180.0;
            var a = radLat1 - radLat2;
            var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
            var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
                Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
            s = s * 6378.137;
            s = Math.round(s * 10000) / 10000;
            let dis = s.toFixed(2)
            dis = dis * 1
            return dis // 单位千米
        },

        async xiajiaYes() {
            let data = await this.$api.base.rentOut(this.paramXj)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: "下架成功",
                    type: 'success',
                    offset: 100
                });
            }
            this.showXiajia = false
            this.rentList()
        },
        async xiajia(id, status) {
            this.paramXj.warehouseId = id
            this.paramXj.status = status
            if (status == 2) {
                this.showXiajia = true
                return
            }
            if (status == 1) {
                let data = await this.$api.base.rentOut(this.paramXj)
                console.log(data);
                if (data == null) {
                    this.$message({
                        message: "上架成功",
                        type: 'success',
                        offset: 100
                    });
                }
                this.rentList()
            }
        },
        async deleteYes() {
            let data = await this.$api.base.rentOut(this.deleteParam)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: "删除成功",
                    type: 'success',
                    offset: 100
                });
            }
            this.showDelete = false
            this.rentList()
        },
        shanchu(id) {
            this.deleteParam.warehouseId = id
            this.showDelete = true
        },
        selectLat() {
            this.mapShow = true
            this.mapOne = 1

        },
        async changeSheng(e) {
            this.param.province = e
            this.cityList = await this.getArea(e)

        },
        async changeShi(e) {
            this.param.city = e
            this.areaList = await this.getArea(e)

        },
        async changeQu(e) {
            this.param.county = e


        },

        // 仓库地址
        changeAddress: function (e) {
            console.log('picker发送选择改变，携带值为', e)
        },
        // 仓库经纪人
        changeBroker(e) {
            console.log(e);
            this.param.brokerId = e
        },
        // 仓库性质
        changeNature(e) {
            console.log(e)
            this.param.properties = e
            // this.natureName = this.natureList[e.detail.value].name
        },
        // 存放品类
        changeCategory(e) {
            console.log(e);
            this.param.categoryIds = e.join(",")
            let arr = []
            for (let i = 0; i < this.selectAll.common_category.length; i++) {
                for (let j = 0; j < e.length; j++) {
                    if (this.selectAll.common_category[i].categoryId == e[j]) {
                        arr[arr.length] = this.selectAll.common_category[i].categoryName
                    }
                }


            }
            console.log(arr);
            this.cateSelectList = arr
        },
        // 仓库类型
        changeType(e) {
            // console.log(e)
            // this.warehouse_extra = this.selectAll.warehouse_extra[e.detail.value].itemName
            this.param.warehouseType = e.join(",")
            let arr = []
            for (let i = 0; i < this.selectAll.warehouse_extra.length; i++) {
                for (let j = 0; j < e.length; j++) {
                    if (this.selectAll.warehouse_extra[i].tagId == e[j]) {
                        arr[arr.length] = this.selectAll.warehouse_extra[i].itemName
                    }
                }


            }
            this.wareSelectList = arr
            // console.log(this.wareSelectList);
        },
        // 物联网功能
        changeEffect(e) {
            console.log('picker发送选择改变，携带值为', e)
            // this.effectName = this.selectAll.warehouse_iot[e.detail.value].itemName
            this.param.iotAbility = e.join(",")
        },


        // 是否有铁路专用线
        changeTielu(index) {
            console.log(index);
            if (index == 0) {
                this.tieluDis = true
                this.param.railwayCount = ''
                this.param.railwayLength = ''
            } else if (index == 1) {
                this.tieluDis = false
            }
            console.log(this.param.railwayIsHave);

        },
        // 是否上线兑货宝
        changeShangxian(index) {
            if (index == 1) {
                this.shangxian = true
            } else if (index == 0) {
                this.shangxian = false
            }
            if (this.shangxian) {
                this.param.isCustomer = 1
            } else {
                this.param.isCustomer = 0
            }
        },
        // 特色标签选择
        changeLabel(e) {
            console.log(e);
            this.param.featureStr = e
        },
        // changeLabel(index, id) {
        //     this.labelIndex = index
        //     this.param.featureStr = id
        // },
        // 建筑结构
        changeJiegou(e) {
            console.log('picker发送选择改变，携带值为', e)
            // this.jiegouName = this.jiegouList[e.detail.value].name
            this.param.propertyStructure = e
        },
        // 防火等级
        changeLevel(e) {
            console.log('picker发送选择改变，携带值为', e)
            this.param.fireRating = e
        },
        // 升降平台
        changePingtai(e) {
            console.log('picker发送选择改变，携带值为', e)
            this.pingtaiName = this.pingtaiList[e.detail.value].name
            this.param.columnSpacing = this.pingtaiList[e.detail.value].type
        },
        // 选择地址类型
        changeLx(e) {
            this.addFlag = false
            console.log(this.selectAll.map[e.detail.value]);
            this.addList[this.addIndex].type = this.selectAll.map[e.detail.value].tagId
            this.addList[this.addIndex].typeName = this.selectAll.map[e.detail.value].itemName

            console.log(this.addList[this.addIndex]);
            this.addFlag = true
            // this.addList[this.addIndex].type = this.selectAll.map[e.detail.value].itemName

        },
        changeLeixing(item, index) {
            console.log(index);
            this.addIndex = index

        },
        editRent(id) {
            this.step = 1
            this.detailParam.id = id
            this.param.warehouseId = id
            this.rentDetail()

        },
        async rentDetail() {
            this.loading = true
            let data = await this.$api.base.rentDetail(this.detailParam)
            console.log(666, data);
            if (data.code == -9999) {
                this.loading = false
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });

                return
            }
            this.param.opType = 2
            this.addressValue = data.info.oneName
            this.cityValue = data.info.twoName
            this.areaValue = data.info.threeName
            this.param.warehouseName = data.info.warehouseName
            this.param.address = data.info.address
            this.param.brokerId = data.info.brokers[0].brokerId
            this.brokerName = data.info.brokers[0].brokerName
            this.param.province = data.info.province
            this.param.city = data.info.city
            this.param.county = data.info.county
            this.param.properties = data.info.properties
            this.natureName = this.natureList[data.info.properties].name
            this.param.railwayCount = data.info.railwayCount
            this.param.railwayLength = data.info.railwayLength
            this.show1 = true
            let arr1 = []
            for (var i = 0; i < data.info.categories.length; i++) {
                arr1[arr1.length] = data.info.categories[i].categoryId
            }
            console.log(arr1);
            this.typeSelect = arr1
            this.param.categoryIds = arr1.join(",")
            this.param.landArea = data.info.landArea
            this.param.outdoorsArea = data.info.outdoorsArea
            this.param.warehouseArea = data.info.warehouseArea
            // this.warehouse_extra = data.info.warehouseType[0].itemName
            // this.effectNameSelect = data.info.iotTags

            let arra = []
            if (data.info.iotTags) {
              for (let i = 0; i < data.info.iotTags.length; i++) {
                  arra[arra.length] = data.info.iotTags[i].tagId
              }
            }
            this.effectNameSelect = arra



            let arrayss = []
            let arraysss = []
            for (let i = 0; i < data.info.warehouseType.length; i++) {
                arrayss[arrayss.length] = data.info.warehouseType[i].tagId
                arraysss[arraysss.length] = data.info.warehouseType[i].itemName

            }
            this.wareSelectList = arraysss

            let array1 = []
            for (let i = 0; i < data.info.categories.length; i++) {

                array1[array1.length] = data.info.categories[i].categoryName
            }
            this.cateSelectList = array1
            // for (let i = 0; i < this.selectAll.warehouse_extra.length; i++) {
            //     for (let j = 0; j < e.length; j++) {
            //         if (this.selectAll.warehouse_extra[i].tagId == e[j]) {
            //             arr[arr.length] = this.selectAll.warehouse_extra[i].itemName
            //         }
            //     }


            // }
            // this.wareSelectList = arr
            // console.log(this.wareSelectList);
            this.param.warehouseType = arrayss.join(",")
            this.wareSelect = arrayss
            console.log(arrayss);
            this.param.iotAbility = data.info.iotAbility
            this.param.railwayIsHave = data.info.railwayIsHave
            if (this.param.railwayIsHave == 0) {
                this.tieluDis = true

            } else if (this.param.railwayIsHave == 1) {
                this.tieluDis = false
            }
            this.tielu = data.info.railwayIsHave
            this.param.isCustomer = data.info.isCustomer
            this.shangxian = data.info.isCustomer
            // let arr2 = []
            // for (var i = 0; i < data.info.featureTags.length; i++) {
            // 	arr2[arr1.length] = data.info.featureTags[i].tagId
            // }


            let arrays = []
            for (let i = 0; i < data.info.featureTags.length; i++) {
                arrays[arrays.length] = data.info.featureTags[i].tagId

            }
            this.biaoqian = arrays
            this.param.featureStr = arrays.join(",")
            // if (this.param.featureStr.length > 0) {
            //     this.param.featureStr = data.info.featureTags[0].tagId
            //     for (var i = 0; i < this.selectAll.warehouse_feature.length; i++) {
            //         if (this.selectAll.warehouse_feature[i].tagId == data.info.featureTags[0].tagId) {
            //             this.labelIndex = i
            //         }
            //     }
            // }
            console.log(data.info.featureTags);

            this.param.fireRating = data.info.fireRating
            this.levelName = this.levelList[data.info.fireRating - 1].name
            this.param.propertyStructure = data.info.propertyStructure
            this.jiegouName = this.jiegouList[data.info.propertyStructure - 1].name
            this.param.bearing = data.info.bearing
            this.param.high = data.info.high
            this.param.railwayPlatformWidth = data.info.railwayPlatformWidth
            this.param.platformHigh = data.info.platformHigh
            this.param.deep = data.info.deep
            this.param.columnSpacing = data.info.columnSpacing * 1
            this.pingtaiName = this.pingtaiList[data.info.columnSpacing].name
            this.param.service = data.info.service
            this.param.description = data.info.description

            let arrBan = []
            for (let i = 0; i < data.info.warehouseBanners.length; i++) {
                let obj = {
                    fileName: data.info.warehouseBanners[i].fileName,
                    filePath: data.info.warehouseBanners[i].filePath
                }
                arrBan[arrBan.length] = obj
            }
            if (arrBan.length == 0) {
                this.param.banners = ''
            } else {
                this.param.banners = JSON.stringify(arrBan)
            }

            this.fileList2 = data.info.warehouseBanners
            console.log(this.fileList2);
            // this.banner = [1]
            this.param.longitude = data.info.longitude
            this.param.latitude = data.info.latitude
            this.param.cover = data.info.cover
            let obj3s = {
                url: data.info.thumbnail
            }
            this.fileList1[0] = obj3s
            console.log(data.info.cover);

            let obj = {}
            let arr3 = []
            if (data.info.warehouseMapConfigs == null) {

            } else {
                for (var i = 0; i < data.info.warehouseMapConfigs.length; i++) {
                    obj = {
                        name: '', //名称
                        address: '', //地点
                        longitude: '', //经纬度
                        latitude: '',
                        distance: '', //距离
                        type: '', //类型
                        typeName: ''
                    }
                    obj.name = data.info.warehouseMapConfigs[i].name
                    obj.address = data.info.warehouseMapConfigs[i].address
                    obj.longitude = data.info.warehouseMapConfigs[i].longitude
                    obj.latitude = data.info.warehouseMapConfigs[i].latitude
                    obj.distance = data.info.warehouseMapConfigs[i].distance
                    obj.type = data.info.warehouseMapConfigs[i].codeSetId
                    obj.typeName = data.info.warehouseMapConfigs[i].itemName
                    arr3[arr3.length] = obj

                }
                this.param.relationAddress = JSON.stringify(arr3)
                this.addList = arr3
                console.log(arr3);
            }

            this.loading = false
            this.fabuEdit = true
            nav.classList.add("fixedNav");



        },
        // 获取第一层省市区
        async getAreaSheng() {
            let data = await this.$api.base.getArea()
            // console.log(data);

            this.addressList = data.list
            for (var i = 0; i < this.addressList.length; i++) {
                this.newProvinceDataList[0].push(this.addressList[i].name)
            }
            let arr1 = await this.getArea(this.addressList[0].id)
            for (var i = 0; i < arr1.length; i++) {
                this.newProvinceDataList[1].push(arr1[i].name)
                this.arr1.push(arr1[i])
            }
            let arr2 = await this.getArea(arr1[0].id)
            for (var i = 0; i < arr2.length; i++) {
                this.newProvinceDataList[2].push(arr2[i].name)
                this.arr2.push(arr2[i])
            }


        },
        // 根据父级id获取市区
        async getArea(id) {
            let data = await this.$api.base.getArea({
                areaPid: id
            })
            // console.log(data.list);
            return data.list
            // this.addressList = data.list

        },
        // 获取初始化选项列表
        async getSelect() {
            let data = await this.$api.base.getSelect()
            console.log(data);
            this.selectAll = data.info

        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.params.page = val
            this.rentList()
        },
        async rentList() {
            this.loading = true
            let {
                params
            } = this;
            let newList = await this.$api.base.rentList(params);
            console.log(newList);
            this.list = newList
            this.loading = false

        },
        changeAddressType(e) {
            console.log(e);
            console.log(this.addList);
        },
        changeAddressTypes(e) {
            console.log(e);
        },
        // handleEdit(index, row) {
        //     console.log(index, row);
        // },
        handleDelete(index, row) {
            console.log(index, row);
            this.addList.splice(index, 1)
            this.param.relationAddress = JSON.stringify(this.addList)
        },
        handleEdit(index, row) {
            console.log(index, row);

            for (let i = 0; i < this.selectAll.map.length; i++) {
                if (this.selectAll.map[i].tagId == row.type) {
                    this.addList[index].typeName = this.selectAll.map[i].itemName
                }

            }
            console.log(this.addList);
            this.addFlag = false

            console.log(this.addList);
            setTimeout(() => {
                this.addFlag = true
            }, 1)

            this.param.relationAddress = JSON.stringify(this.addList)
        },


        handleRemove(file, fileList) {
            console.log(file, fileList);

        },
        handleRemove1(file, fileList) {
            console.log(file);
            console.log(fileList);
            let arrBan = []
            for (let i = 0; i < fileList.length; i++) {
                let obj = {
                    fileName: fileList[i].fileName,
                    filePath: fileList[i].filePath
                }
                arrBan[arrBan.length] = obj
            }
            if (arrBan.length == 0) {
                this.param.banners = ''
            } else {
                this.param.banners = JSON.stringify(arrBan)
            }



        },
        uploadYulan(response, file, fileList) {
            console.log(response, file, fileList);
            console.log(this.fileList2);
            let arr = []
            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].response != undefined) {
                    let obj = {
                        fileName: fileList[i].response.data.fileName,
                        filePath: fileList[i].response.data.filePath
                    }
                    arr[arr.length] = obj
                } else {
                    let obj = {
                        fileName: fileList[i].fileName,
                        filePath: fileList[i].filePath
                    }
                    arr[arr.length] = obj
                }
            }
            console.log(arr);
            let arr1 = []
            for (let i = 0; i < arr.length; i++) {
                let obj = {
                    fileName: arr[i].fileName,
                    filePath: arr[i].filePath
                }
                arr1[arr1.length] = obj
            }
            console.log(arr1);
            this.param.banners = JSON.stringify(arr1)

        },






        uploadFengmian(response, file, fileList) {
            console.log(response, file, fileList);
            let obj = {
                fileName: file.response.data.fileName,
                filePath: file.response.data.filePath
            }
            this.param.cover = JSON.stringify(obj)

        },
        handleBefore(file) {
            console.log(file);

            const isLt1M = file.size / 1024 / 1024 / 5 < 1
            if (!isLt1M) {
                this.$message({
                    message: "上传图片大小不能超过 5M",
                    type: 'error',
                    offset: 100
                });
                return false
            }
            return true

        },
        handlePreview(file) {
            console.log(file);


        },

        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },

        onEditorBlur() {
            //失去焦点事件
            // console.log('失去焦点');
        },
        onEditorFocus() {
            //获得焦点事件
            // console.log('获得焦点事件');
        },
        onEditorChange() {
            //内容改变事件
            // console.log('内容改变事件');
            console.log(this.param.description);
            // Bus.$emit('getEditorCode', this.content)
        },
        // submitForm(formName) {


        //     // this.$refs[formName].validate((valid) => {
        //     //     if (valid) {
        //     //         alert('submit!');
        //     //     } else {
        //     //         console.log('error submit!!');
        //     //         return false;
        //     //     }
        //     // });
        //     this.step = 2
        // },
        //表单验证
        validate(param) {
            // console.log(this.$util);
            let validate = new this.$util.Validate();
            this.ruleRent.map(item => {
                let {
                    name,
                } = item
                validate.add(param[name], item);
            })
            let message = validate.start();
            return message;
        },
        submitForm(formName) {
            console.log(this.param);
            let msg = this.validate(this.param);
            if (msg) {
                // uni.$u.toast(msg)
                this.$message({
                    message: msg,
                    type: 'warning',
                    offset: 100
                });
                return;
            }
            this.step = 2
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleClick(tab, event) {
            this.flag = false
            setTimeout(() => {
                this.currentPage4 = 1
                this.flag = true
            }, 500)
            this.activeName = tab.name
            console.log(tab.index, tab.name);
            this.params.page = 1
            // this.currentPage4 = 1
            // console.log(e);
            // this.current = e.index
            if (tab.index == 0) {
                this.params.status = 0
                this.params.upDown = 0
            } else if (tab.index == 1) {
                this.params.status = 2
                this.params.upDown = 1
            } else if (tab.index == 2) {
                this.params.status = 0
                this.params.upDown = 2
            } else if (tab.index == 3) {
                this.params.status = 1
                this.params.upDown = 0
            } else if (tab.index == 4) {
                this.params.status = 3
                this.params.upDown = 0
            }
            this.rentList()
        },
        // async xiajia(id, status) {
        //     this.paramXj.warehouseId = id
        //     this.paramXj.status = status
        //     // if (status == 2) {
        //     //     this.showXiajia = true
        //     //     return
        //     // }
        //     // if (status == 1) {
        //     let data = await this.$api.base.rentOut(this.paramXj)
        //     console.log(data);

        //     this.rentList()
        //     // }




        // },
        handleCloses() {
            this.mapShow = false
        },
        handleClose() {
            this.fabuEdit = false
            nav.classList.remove("fixedNav");
        },

    },
};
</script>
<style scoped lang="less">
/deep/ .el-dialog {

    border-radius: 10px !important;
}

.tab {
    /deep/ .el-tabs__nav-wrap {

        border-bottom: 1px solid #CCCCCC;
        padding-left: 20px;
    }
}

/deep/ .el-pagination .btn-next {
    // width: 20px !important;
    background: center center no-repeat #00B079;

}

/deep/ .el-pagination .btn-prev {
    background: center center no-repeat #00B079;


}

.modal {
    display: block;
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.modal-mask {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.modal-container {
    position: relative;
    z-index: 99999999999999999999999999999999999999999;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
}

.title_other {
    text-align: left;
    width: 195px;
    // height: 150px;
    padding: 10px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    color: #FFFFFF;
    line-height: 20px;
    position: absolute;
    // right: 0;
    top: 40px;
    left: 100px;
    z-index: 99;
}

/deep/ .el-pager li.active {
    color: #00B079;
    cursor: default;
}

/deep/ .el-pager li:hover {
    color: #00B079;
}

.shenhe {
    .shenheimg {
        text-align: center;

        img {
            width: 200px;
            height: 200px;
        }
    }

    .tishi {
        div {
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #CCCCCC;
            line-height: 28px;
        }
    }

    .reason {

        width: 60%;
        margin: 0 auto;
        padding: 30px 50px;
        // height: 156px;
        background: #F0F0F0;
        border-radius: 10px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
    }

    .buttn {
        width: 260px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #00B079;
        border-radius: 10px 10px 10px 10px;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin: 50px auto;
        cursor: pointer;
    }
}

/deep/ .el-textarea__inner:focus {
    outline: 0;
    border-color: #00B079;
}

/deep/ .el-tabs__item.is-active {
    color: #00B079;
}

/deep/ .el-tabs__item {
    color: #999999 !important;
    font-size: 18px !important;
}

/deep/ .el-tabs__active-bar {

    background-color: #00B079;

}

/deep/ .el-tabs__item:hover {
    color: #00B079;
}

/deep/ .el-tabs__item {
    padding: 0px 24%;

}



/deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #00B079;
    background: #00B079;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #00B079;
}

/deep/ .el-radio__inner:hover {
    border-color: #00B079;
    // background: #00B079;
    // color: #00B079;
}

/deep/ .el-table .cell {

    font-size: 12px;
}

.main {
    /deep/ .el-loading-spinner .path {

        stroke: #00B079 !important;
    }

    .popup {
        .form {
            .stepList {
                .step {
                    display: flex;
                    justify-content: center;

                    img {
                        width: 220px;
                    }
                }
            }

            .form_con {
                margin-top: 50px;
                padding: 0 110px;

                .form_row {
                    display: flex;
                    // justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    // height: 42px;
                    // margin-bottom: 30px;

                    .every_form {
                        // display: flex;
                        // align-items: center;
                        // flex-wrap: wrap;
                        // justify-content: right;
                        width: 50%;


                        .form_inp {
                            width: 70%;
                            cursor: pointer;
                            font-size: 16px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #00B079;

                            span {
                                cursor: pointer;
                                font-size: 16px;
                                font-family: PingFang SC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #00B079;
                            }


                        }

                        span {
                            margin-left: 10px;
                            font-size: 14px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }


                    }


                }
            }
        }
    }

    .flex_center_a {
        display: flex;
        align-items: center;

    }

    .flex_center_a_w {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .flex_between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .listData {
        // padding: 110rpx 30rpx 30rpx 30rpx;

        .every_data {
            background: #FFFFFF;
            // padding: 30px;
            padding: 30px 0;
            // border-radius: 20px;
            border-bottom: 1px solid #CCCCCC;
            margin-bottom: 20px;

            .data_content {

                .data_img {

                    margin-right: 20px;

                    img {
                        width: 200px;
                        height: 128px;
                        border-radius: 10px;
                        overflow: hidden;
                    }
                }

                .content {
                    // background-color: red;
                    width: calc(100vw - 380px);

                    .data_title {
                        font-size: 18px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 21px;
                        margin-bottom: 10px;

                        .data_mark2 span {
                            font-size: 16px;
                            color: #999999;
                        }
                    }

                    .data_label {
                        font-size: 20px;
                        color: #09B37E;

                        .every_label {
                            padding: 2px 10px;
                            background: #E0F9F1;
                            border-radius: 4px;
                            border: 1px solid #09B37E;
                            margin-right: 16px;
                            margin-bottom: 16px;
                        }
                    }

                    .data_address {
                        font-size: 16px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 19px;
                        flex-wrap: wrap;

                        .data_btn {
                            margin-top: 20px;

                            .data_mark1 {
                                font-size: 28px;
                                color: #333333;
                            }



                            .data_mark3 {
                                font-size: 28px;
                                color: #09B37E;
                            }

                            .data_mark4 {
                                font-size: 28px;
                                color: #D00000;
                            }

                            .btns {
                                font-size: 28px;
                                font-weight: bold;

                                // color: #FFFFFF;
                                .btn {
                                    cursor: pointer;
                                    padding: 4px 20px;
                                    border-radius: 200px;
                                    margin-left: 20px;
                                }

                                .btn1 {
                                    background: #09B37E;
                                    color: #FFFFFF;
                                    border: 1px solid #09B37E;
                                }

                                .btn2 {
                                    border: 1px solid #09B37E;
                                    color: #09B37E;
                                }

                                .btn3 {
                                    border: 1px solid #D00000;
                                    color: #D00000;
                                }
                            }
                        }
                    }
                }

            }
        }


    }
}

.popup1 {
    /deep/ .el-textarea__inner {
        height: 100px !important;
    }

    /deep/ .el-input__inner {
        border-color: #DCDFE6 !important;
    }

    /deep/ .el-input__inner {
        border-color: #DCDFE6 !important;
    }

    /deep/ .el-table__header-wrapper {
        border-radius: 10px !important;

    }

    /deep/ .el-table .cell {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }

    /deep/ .el-table th.el-table__cell {


        background: #F0F0F0 !important;
    }

    /deep/ .el-upload-list--picture .el-upload-list__item-thumbnail {

        width: 200px !important;
        height: 110px !important;

    }

    /deep/ .el-upload-list--picture .el-upload-list__item {

        height: 132px !important;
    }

    /deep/ .el-upload-list--picture .el-upload-list__item.is-success .el-upload-list__item-name {
        line-height: 112px !important;
        margin-top: 0;
    }

}

.popup {
    /deep/ .el-form-item__label {

        font-size: 18px !important;
        font-family: PingFang SC-Regular, PingFang SC !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }

    /deep/ .el-radio__label {
        font-size: 18px !important;
        font-family: PingFang SC-Regular, PingFang SC !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }
}

.fuwenben {
    /deep/ .el-form-item__content {
        line-height: 22px !important;
    }

    /deep/ .ql-container {
        min-height: 100px !important;
    }
}

/deep/ .el-dialog__title {

    font-weight: 600;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #00B079;
    border-color: #00B079;
}

/deep/ .el-checkbox__inner:hover {
    border-color: #00B079;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #00B079;
}
</style>
